import api from '@/services';

export const apiChatResponse = async (payload: {
  query: string;
  wordLimit?: number;
  twinName?: string;
}) => {
  return await api.post('/twin/chat', payload);
};

export const apiGetTwin = async (payload: { twinName: string }) => {
  return await api.get('/twin/get-twin', { params: payload });
};

export const apiGetSpeechToken = async () => {
  return await api.get('/twin/get-speech-token');
};

export const apiGetTwinCategories = async (payload: { twinName: string }) => {
  return await api.get('/twin/categories', { params: payload });
};
